<template>
  <!--  <v-container style="background-color: #F0F2F5" fluid fill-height>-->
  <v-row style="align-items: center" class="py-5">
    <v-col align="center">
      <v-card
        elevation="0"
        style="background-color: #E9EFF5"
        class="hidden-md-and-down rounded-circle text-center d-flex flex-column align-center justify-center"
        :width="cardSize"
        :height="cardSize"
        rounded
      >
        <v-icon color="#A3B1C7" class="ml-15" size="80">mdi-pencil</v-icon>
        <v-icon color="#A3B1C7" class="mr-15" size="100"
          >mdi-vector-polygon</v-icon
        >
      </v-card>
      <p class="mt-5 text-h5">{{ $t("baseline.almostThere") }}</p>
      <p class="label-color pl-1">
        {{ $t("emptyState.noVSInfo") }}
      </p>
      <v-btn
        color="#039BE5"
        rounded
        class="white--text font-weight-bold button-text"
        :to="{
          name: 'MapView'
        }"
        >{{ $t("emptyState.drawStations") }}
      </v-btn>
    </v-col>
  </v-row>
  <!--  </v-container>-->
</template>

<script>
export default {
  name: "EmptyStateVs",
  computed: {
    cardSize() {
      const height = window.innerHeight;
      if (height > 900) {
        return 320;
      } else if (height > 800) {
        return 300;
      } else if (height > 700) {
        return 250;
      } else {
        return 0;
      }
    }
  }
};
</script>

<style scoped>
.button-text {
  text-transform: unset !important;
}
.label-color {
  color: #173466;
}
</style>
